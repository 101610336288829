export const years = [
{
  item: "male",
  value: 2019
},
{
  item: "female",
  value: 2020
},
{
  item: "other",
  value: 2021
}
  ];

export const months = [
  {
    item: "0-2am",
    value: "Jan"
  },
  {
    item: "2-4am",
    value: "Feb"
  },
  {
    item: "4-6am",
    value: "Mar"
  },
  {
    item: "6-8am",
    value: "Apr"
  },
  {
    item: "8-10am",
    value: "May"
  },
  {
    item: "10-12pm",
    value: "Jun"
  },
  {
    item: "0-2pm",
    value: "Jul"
  },
  {
    item: "2-4pm",
    value: "Aug"
  },
  {
    item: "4-6pm",
    value: "Sep"
  },
  {
    item: "6-8pm",
    value: "Oct"
  },
  {
    item: "8-10pm",
    value: "Nov"
  },
  {
    item: "10-12pm",
    value: "Dec"
  },
];

export const costCentres = [
  {
    item: "city center",
    value: "CC100"
  },
  {
    item: "near city",
    value: "CC101"
  },
  {
    item: "countryside",
    value: "CC102"
  },
  {
    item: "CBD",
    value: "CC200"
  },
  {
    item: "Papanui",
    value: "CC201"
  },
  {
    item: "Woolston",
    value: "CC202"
  },
  {
    item: "Avonhead",
    value: "CC300"
  },
  {
    item: "Lincoln",
    value: "CC301"
  },
  {
    item: "Rolleston ",
    value: "CC302"
  }
];

export const accounts = [
  {
    item: "<10",
    value: 1000000
  },
  {
    item: "11-15",
    value: 1000001
  },
  {
    item: "15-18",
    value: 1000002
  },
  {
    item: "18-20",
    value: 1000004
  },
  {
    item: "20-23",
    value: 2000000
  },
  {
    item: "23-26",
    value: 2000001
  },
  {
    item: "26-30",
    value: 2000002
  },
  {
    item: "30-35",
    value: 2000003
  },
  {
    item: "35-40",
    value: 2000005
  },
  {
    item: "40-45",
    value: 3000000
  },
  {
    item: "45-50",
    value: 3000001
  },
  {
    item: "50-55",
    value: 3000002
  },
  {
    item: ">55",
    value: 4000000
  },  
];
