import logo from './Mako.png';
import React, { useState } from "react";
import axios from "axios";

import './App.css';
// import "./app.scss";
import {
  Form,
  FormGroup,
  Select,
  SelectItem,
  Button,
  Loading,
} from "carbon-components-react";
import { years, months, costCentres, accounts } from "./utils";
import BarChart from "./components/dataviz/BarChart";
import { URL } from './Url';
import './textbg.css'
import './buttonbusiness.css'

function App() {
  const [year, setYear] = useState(2019);
  const [month, setMonth] = useState("Jan");
  const [costCentre, setCostCentre] = useState("CC101");
  const [account, setAccount] = useState(1000000);
  const [prediction, setPrediction] = useState();
  const [scores, setScores] = useState([]);

  const runPred =  async (year, month, costCentre, account) => {
    // console.log(year, month, costCentre, account)
    setPrediction("Scoring");
    const res =  await axios.post(`${URL}/api/wml/score`, {
      year,
      month,
      costCentre,
      account,
    });
    console.log(res)
    setPrediction(res.data.predictions[0].values[0]);
    console.log(res.data.predictions[0].values[0]);
    setScores([
      ...scores,
      {
        group: year + month + costCentre + account,
        value: res.data.predictions[0].values[0][0],
      },
    ]);
    console.log(prediction, scores);
  };


  return (
    <div className="App">
        <div className="App-header">

          <img src={logo} className="App-logo" alt="logo" />
          <p className='title'>
          Mako is our artificial intelligent brain that we use to predict Interest Scores.
        </p>    
        </div>
        <div className="mainContainer">
        <Form>
            <FormGroup>
            <Select
              id="select-0"
              labelText="Gender"
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((yearVal) => (
                <SelectItem text={yearVal.item} value={yearVal.value} />
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Select
              id="select-1"
              labelText="Time"
              onChange={(e) => setMonth(e.target.value)}
            >
              {months.map((monthVal) => (
                <SelectItem text={monthVal.item} value={monthVal.value} />
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Select
              id="select-2"
              labelText="Area"
              onChange={(e) => setCostCentre(e.target.value)}
            >
              {costCentres.map((ccVal) => (
                <SelectItem text={ccVal.item} value={ccVal.value} />
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Select
              id="select-3"
              labelText="Age"
              onChange={(e) => setAccount(e.target.value)}
            >
              {accounts.map((accVal) => (
                <SelectItem text={accVal.item} value={accVal.value} />
              ))}
            </Select>
          </FormGroup>
        
          <div className="heroneon" onClick={(e) => runPred(year, month, costCentre, account)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Predict Interest Score
              </div>

        </Form>
        <div className="predictionContainer">
          {prediction !== "Scoring" && prediction ? "The model predicted" : ""}
          <div className="predictionResult">
            {/* {prediction} */}
            {prediction === "Scoring" ? (
              <Loading description="Loading..." />
            ) : !prediction ? (
              ""
            ) : (
              parseInt(prediction)
            )}
          </div>
        </div>
        {/* <div className="chartContainer">
          {scores.length === 0 ? "" : <BarChart data={scores} />}
        </div> */}
        </div>
    </div>
  );
}

export default App;
